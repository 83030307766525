<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="权限设置"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>
      <a-tree
        v-model="checkedKeys"
        checkable
        check-strictly
        :tree-data="treeData"
        :replace-fields="replaceFields"
        :expanded-keys="expandedKeys"
        @check="onCheck"
        @expand="onExpand"
      />
    </a-modal>
  </div>
</template>

<script>
import { findMenuPermissions, findPermissions, updatePagePermissions } from '@/api/permission'
export default {
  name: 'SystemRolePermission',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    isSystem: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'system_role_permission' }),
      submitting: false,
      treeData: [],
      checkedKeys: { checked: [] }, // 控制页面显示是否选中
      expandedKeys: [],
      replaceFields: {
        title: 'name',
        key: 'endpoint'
      }
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchAllPageData()
  },
  methods: {
    fetchAllPageData() {
      findMenuPermissions(this.isSystem ? 'super_admin' : 'tenant_admin').then((res) => {
        this.treeData = res.data.pages
        this.getExpandedKeys(res.data.pages)
        this.fetchData()
      })
    },

    getExpandedKeys(pages) {
      for (const page of pages) {
        if (page.children && page.children.length > 0) {
          if (['menu:system_home', 'menu:tenant_home'].includes(page.endpoint)) {
            page.disabled = true
          }
          this.expandedKeys.push(page.endpoint)
          this.getExpandedKeys(page.children)
        }
      }
    },

    fetchData() {
      findPermissions(this.id).then((res) => {
        if (res.code === 0) {
          if (res.data.pages) {
            const pages = res.data.pages
            for (const page of pages) {
              this.checkedKeys.checked.push(page.endpoint)
            }
          }

          this.setSystemHomePermission()
        }
      })
    },

    setSystemHomePermission() {
      if (this.isSystem) {
        if (!this.checkedKeys.checked.includes('menu:system_home')) {
          this.checkedKeys.checked.push('menu:system_home')
          updatePagePermissions(this.id, { endpoints: this.checkedKeys.checked })
        }
      }
    },

    onCheck(checkedKeys, info) {
      updatePagePermissions(this.id, { endpoints: checkedKeys.checked })
    },

    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
    },

    closeModal() {
      this.isShow = false
      // 告知父组件已完成
      this.$emit('completed')
    }
  }
}
</script>

